const rows = [
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    // Image: "../../../assets/images/ImgIcon.svg",
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  {
    StudyCircle: '10th A Class',
    Subject: 'Science',
    Topic: 'The Human Eye and the..',
    Day: ' 8 Aug 2021',
    Student: 'Sagar',
    Score: 70,
    Attendance: '66%',
  },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj7",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj8",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talk: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj9",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj10",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj11",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj12",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj13",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj14",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // },
  // {
  //     Edit: "Yes",
  //     Enabled: "Yes",
  //     Country: "India",
  //     AchieverID: 7878,
  //     Name: "bbkjsdlksj15",
  //     TitleCaption: "dskahd sjdhksa skjdhskajdn",
  //     Courses: 5,
  //     Notes: 6,
  //     Talks: 5
  // }
];

export default rows;
