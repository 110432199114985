import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './StudentFeedback.css';
import { BASE_URL } from '../../config/Api';
import Navbar from './../AchieversInfo/Header/NavbarInfo';
import { toast } from 'react-toastify';
import moment from 'moment';

const StudentFeedback = (props) => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [fromDate, setFromDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
  const [feedbacks, setFeedbacks] = useState([]);
  const initialRender = useRef(false);
  const token = window.localStorage.getItem('token');
  const auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  console.log({ category, fromDate, toDate, feedbacks });

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const feedabackCategory = await axios.get(`${BASE_URL}/master_data/feedback_categories`, auth);
        // console.log("feedabackCategory", feedabackCategory?.data?.data)
        console.log('feedabackCategory');
        setCategories(feedabackCategory?.data?.data);
      } catch (err) {
        console.log('error in fetching student feedback category', err);
        toast.error('Error occured in fetching category');
      }
    };
    if (!initialRender.current) {
      fetchCategory();
    } else {
      initialRender.current = true;
    }
    // eslint-disable-next-line
  }, [category]);

  function handleNavbar(e, selectedOption) {
    if (selectedOption === 'feedback_category') {
      setCategory(e.target.value);
    }
  }

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const getFeedback = async () => {
    try {
      const schoolFeedback = await axios.get(
        `${BASE_URL}/feedback?feedback_category=${category}&start_date=${fromDate}&end_date=${toDate}`,
        auth,
      );
      if (schoolFeedback?.data?.data.length > 0) {
        setFeedbacks(schoolFeedback?.data?.data);
      } else {
        toast.info('Feedbacks are not found for above selected category and date');
      }
    } catch (err) {
      console.log('error in fetching student feedback category', err);
      toast.error('Error occured in fetching feedback');
    }
  };

  return (
    <>
      <Navbar
        name="feedback"
        handleNavbar={handleNavbar}
        categories={categories}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        fromDate={fromDate}
        toDate={toDate}
        getFeedback={getFeedback}
      />
      <div
        style={{
          width: '90%',
          maxHeight: '90vh',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '15px',
          marginTop: '20px',
        }}
      >
        {feedbacks.map((ele, index) => (
          <div className="feedback_card" key={index}>
            <div>{ele.feedback}</div>
            <div>{moment(ele.updated).format('DD MMM YYYY')}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default StudentFeedback;
