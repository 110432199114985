import React from 'react';
import DeleteAccountEmailField from '../DeleteAccountEmailField/DeleteAccountEmailField';

const DeletAccountGetOTP = (props) => {
  const { getOtpFunc, showOtpField, handleEmailInput, email, formErrors } = props;

  return (
    <div className="stepper-container__main-form" name="otp-request-form">
      <DeleteAccountEmailField showOtpField={showOtpField} handleEmailInput={handleEmailInput} email={email} />
      {formErrors?.email && <div className="main-form__error-msg">{formErrors?.email}</div>}
      <button className="main-form__submit-btn" type="button" name="request-otp-btn" onClick={getOtpFunc}>
        Request Email OTP
      </button>
    </div>
  );
};

export default DeletAccountGetOTP;
