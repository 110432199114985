import React from 'react';
import DeleteAccountEmailField from '../DeleteAccountEmailField/DeleteAccountEmailField';

const DeleteAccountSubmitOTP = (props) => {
  const {
    showOtpField,
    handleEmailInput,
    email,
    otp,
    otpSubmitFunc,
    inputRefs,
    handleOTPChange,
    handleKeyDown,
    handleOTPInputClick,
    handleCancel,
    formErrors,
  } = props;

  return (
    <div className="stepper-container__main-form" name="otp-submit-form">
      <DeleteAccountEmailField showOtpField={showOtpField} handleEmailInput={handleEmailInput} email={email} />
      <label className="main-form__label" htmlFor="otp">
        Enter Email OTP
      </label>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '5px' }}
      >
        {otp.map((ele, index) => {
          return (
            <input
              key={index}
              name="otp"
              id="otp"
              type="text"
              className="main-form__otp-input-field"
              value={ele}
              ref={(input) => (inputRefs.current[index] = input)}
              onChange={(e) => handleOTPChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onClick={() => handleOTPInputClick(index)}
              maxLength={1}
            />
          );
        })}
      </div>
      {formErrors?.otp && <div className="main-form__error-msg">{formErrors?.otp}</div>}
      <div className="main-form__submit-btn-container">
        <button className="main-form__cancel-btn" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="main-form__submit-btn"
          style={{ marginTop: '15px' }}
          name="submit-otp-btn"
          type="button"
          onClick={otpSubmitFunc}
        >
          Confirm and Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteAccountSubmitOTP;
