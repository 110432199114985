import React, { useState, useEffect } from 'react';
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Pagination from '../../SubComponents/Pagination/Pagination';
// import rows from "../../../achieversNotesData.js";
// import EditIcon from "../../../assets/images/EditIcon.svg";
import ModalComponent from '../../Modal/Modal';
import './achieversNotes.css';
import { Formik, Form, Field } from 'formik';
// import * as Yup from "yup";
import Box from '@mui/material/Box';
// import { Redirect } from "react-router-dom";
import Axios from 'axios';
import { BASE_URL } from '../../config/Api.js';
import { toast, ToastContainer } from 'react-toastify';
import ReactLoading from 'react-loading';
import { UPLOAD_URL } from '../../config/Api.js';
import configuration from '../../config/Config';
import s3 from '../../config/DigitalOcean';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NavbarNotes from './Header/NavbarNotes';
import SearchSuggestion from '../../SubComponents/SearchSuggestion/SearchSuggestion';

const initialValues = {
  country: -1,
  board: -1,
  course: -1,
  subject: -1,
  language: -1,
  topic: -1,
  numberOfPages: '',
  title: '',
  achieversId: '',
  name: '',
  achieverCode: '',
  attachFile: null,
  thumbnailFile: null,
};

var country_id = -1;
var board_id = -1;
var medium_id = -1;
var course_id = -1;
var subject_id = -1;
var topic_id = -1;
// var achieverName = "";
var numberOfPages = '';
// var title = "";
// var achieverCode = "";
var FilePath;
var FilePathThumbnail;
var sourceFilePath = '';
var sourceFilePathThumbnail = '';
var tempTableData = [];
var rowData;
var navCountry_id = -1;
var navBoard_id = -1;
var navMedium_id = -1;
var navCourse_id = -1;
var navSubject_id = -1;
var navTopic_id = -1;
// var numberOfPages = 1;
var oneAchieverData;
var newPreviewURL = [];
// var sourceFilePathPreview = [];
// var URLName = "";
var previewArray = [];
var FilePathPreview;
var URLFilePathPreview = [];
var isEditPreviewArray = [false, false, false, false, false];
// var preview1;
// var preview2;
// var preview3;
// var preview4;
// var preview5;

const thead_column = [
  'Edit',
  'Enable',
  'Country',
  'Achiever ID',
  'Name',
  'Notes Hexa',
  'Updated Date',
  'Title',
  'Board',
  'Courses',
  'Subject',
  // "Language",
  'Topic',
  'Pages',
  'Rating',
  'Views',
  'View Time (Hr)',
];

// const countries = ["Country", "India", "Nepal", "Shrilanka"];
// const boards = ["All Board", "State", "CBSE", "ICSE"];
// const courses = [
//   "All Courses",
//   "1st",
//   "2nd",
//   "3rd",
//   "4th",
//   "5th",
//   "6th",
//   "7th",
//   "8th",
//   "9th",
//   "10th",
// ];
// const subjects = ["All Subjects", "Kannada", "Science", "Mathematics"];
// const languages = ["Kannada", "English", "Telugu"];
// const topics = ["All Topics", "Eye", "Gravity", "Sun"];

// const validationSchema = Yup.object({
//   email: Yup.string().required("Email is required"),
//   password: Yup.string().required("Password is required"),
// });

function AchieversNotes() {
  const [show, setShow] = useState(false);
  const [attachedFile, setAttachedFile] = useState('');
  const [pic, setPic] = useState('');
  const [tableData, setTableData] = useState([]);
  const [dropDownData, setDropDownData] = useState({
    country: [],
    allBoards: [],
    allMediums: [],
    allCourses: [],
    allSubjects: [],
    allTopics: [],
    achieverNameList: [],
  });
  const [editValue, setEditValue] = useState(false);
  const [rowValues, setRowValues] = useState({
    country: '',
    name: '',
    achieversId: 0,
    numberOfPages: '',
    title: '',
    achieverName: '',
    board: '',
    course: '',
    subject: '',
    language: '',
    topic: '',
    hexaCode: '',
    achieverCode: '',
    achieverNotesId: '',
    notesFileURL: '',
    notesThumbnailFileURL: '',
  });
  const [upload, setUpload] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [attThumbnailFile, setAttThumbnailFile] = useState('');
  const [uploadThumbnail, setUploadThumbnail] = useState(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  const [status, setStatus] = useState('All');
  const [startUpload, setStartUpload] = useState(false);
  const [notFilled, setNotFilled] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [navSearch, setNavSearch] = useState('');
  const [state, setstate] = useState({
    country: -1,
    board: -1,
    course: -1,
    subject: -1,
    topic: -1,
    medium: -1,
  });
  const [navbarData, setNavbarData] = useState({
    country: [],
    allBoards: [],
    allMediums: [],
    allCourses: [],
    allSubjects: [],
    allTopics: [],
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [noData, setNoData] = useState(false);
  const [achieverSearch, setAchieverSearch] = useState('');
  const [achieverSelected, setAchieverSelected] = useState('');
  const [searchSuggestion, setSearchSuggestion] = useState(false);
  const [achieverCodeSelected, setAchieverCodeSelected] = useState('');
  const [notesTitleSelected, setNotesTitleSelected] = useState('');
  const [preview, setPreview] = useState([]);
  const [uploadPreview, setUploadPreview] = useState(false);
  const [uploadedPreview, setUploadedPreview] = useState(false);
  const [fileName, setFileName] = useState("")

  // console.log(preview);

  var userId = window.localStorage.getItem('userId');
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  useEffect(() => {
    async function handlePagination() {
      console.log(offSet);
      try {
        // const rowCount = await Axios.get(
        //   `${BASE_URL}/achiever_notes/search/count?country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
        //   Auth
        // );
        // console.log(rowCount.data.data.count);
        // setNumberOfRows(rowCount.data.data.count);
        // if (rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          // `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
        // }
        // if (rowCount.data.data.count === 0) {
        // setNoData(true);
        // setStartUpload(false);
        // }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }
    async function handlePaginationEnabled() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          // `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&is_enabled=true&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&is_enabled=true&country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }

    async function handlePaginationDisabled() {
      console.log(offSet);
      try {
        const getTable = await Axios.get(
          `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&is_enabled=false&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        }
        setStartUpload(false);
      }
    }

    if (status === 'All') {
      handlePagination();
    }
    if (status === 'Enabled') {
      handlePaginationEnabled();
    }
    if (status === 'Disabled') {
      handlePaginationDisabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  const handleClose = () => {
    country_id = -1;
    board_id = -1;
    medium_id = -1;
    course_id = -1;
    subject_id = -1;
    topic_id = -1;
    // achieverName = "";
    numberOfPages = '';
    // title = "";
    // achieverCode = "";
    sourceFilePath = '';
    sourceFilePathThumbnail = '';
    setShow(false);
    setEditValue(false);
    setPic('');
    setAttachedFile('');
    setUpload(false);
    setUploaded(false);
    setAttThumbnailFile('');
    setPreview([]);
    setUploadThumbnail(false);
    setUploadedThumbnail(false);
    setUploadPreview(false);
    setUploadedPreview(false);
    setStartUpload(false);
    setNotFilled(false);
    setSearchSuggestion(false);
    setAchieverSelected('');
    setAchieverSearch('');
    setAchieverCodeSelected('');
    setNotesTitleSelected('');
    isEditPreviewArray = [false, false, false, false, false];
  };
  const handleShow = () => {setShow(true); setFileName("")};

  async function onSubmit(value) {
    console.log(value);
    console.log(achieverSelected, achieverCodeSelected, notesTitleSelected, URLFilePathPreview.length);
    setStartUpload(true);
    if (
      value.country === -1 ||
      // value.language === -1 ||
      value.board === -1 ||
      value.course === -1 ||
      value.subject === -1 ||
      value.topic === -1 ||
      value.numberOfPages === '' ||
      // value.title === "" ||
      achieverSelected === '' ||
      achieverCodeSelected === '' ||
      notesTitleSelected === '' ||
      sourceFilePath === '' ||
      sourceFilePathThumbnail === '' ||
      URLFilePathPreview.length === 0
    ) {
      toast.error('Plase enter details in all the fields');
      setStartUpload(false);
      setNotFilled(true);
    } else {
      if (editValue) {
        const dataEdit = {
          notes_hexa: rowValues.hexaCode,
          country_id: value.country,
          medium_id: value.language,
          board_id: value.board,
          course_id: value.course,
          subject_id: value.subject,
          topic_id: value.topic,
          no_of_pages: value.numberOfPages,
          title: notesTitleSelected,
          achiever_name: achieverSelected,
          notes_file_url: rowValues.notesFileURL,
          is_achiever_notes: true,
          achiever_code: achieverCodeSelected,
          notes_thumbnail_file_url: sourceFilePathThumbnail,
          preview_image_1: URLFilePathPreview[0],
          preview_image_2: URLFilePathPreview[1],
          preview_image_3: URLFilePathPreview[2],
          preview_image_4: URLFilePathPreview[3],
          preview_image_5: URLFilePathPreview[4],
          is_enabled: true,
        };
        console.log(dataEdit);
        try {
          const newAchiever = await Axios.put(`${BASE_URL}/achiever_notes/${rowValues.achieverNotesId}`, dataEdit, Auth);
          console.log(newAchiever);
          toast.success('Successfully Edited Achiever Info');
          handleClose();
          setStartUpload(false);
          fetchTable();
        } catch (error) {
          console.log(error.response);
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          setStartUpload(false);
        }
      } else {
        const data = {
          // notes_hexa: value.hexaCode,
          country_id: value.country,
          medium_id: value.language,
          board_id: value.board,
          course_id: value.course,
          subject_id: value.subject,
          topic_id: value.topic,
          no_of_pages: value.numberOfPages,
          title: notesTitleSelected,
          notes_file_url: sourceFilePath,
          is_achiever_notes: true,
          achiever_code: achieverCodeSelected,
          achiever_name: achieverSelected,
          preview_image_1: URLFilePathPreview[0],
          preview_image_2: URLFilePathPreview[1],
          preview_image_3: URLFilePathPreview[2],
          preview_image_4: URLFilePathPreview[3],
          preview_image_5: URLFilePathPreview[4],
          notes_thumbnail_file_url: sourceFilePathThumbnail,
        };
        console.log(data);
        try {
          const newAchiever = await Axios.post(`${BASE_URL}/achiever_notes`, data, Auth);
          console.log(newAchiever);
          toast.success('Successfully Added Achiever Info');
          handleClose();
          setStartUpload(false);
          fetchTable();
        } catch (error) {
          console.log(error.response);
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          setStartUpload(false);
        }
      }
    }
  }

  async function fetchTableFirst() {
    setStartUpload(true);
    setStatus('All');
    setNumberOfRows(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_notes/search/count?country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
        Auth,
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        // setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        // setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    try {
      const getCountry = await Axios.get(`${BASE_URL}/master_data/countries/`, Auth);
      console.log(getCountry.data.data);
      setDropDownData({ ...dropDownData, country: getCountry.data.data });
      setNavbarData({ ...navbarData, country: getCountry.data.data });
      setStartUpload(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  }

  async function fetchTable() {
    setStartUpload(true);
    setStatus('All');
    setNumberOfRows(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_notes/search/count?country_id=${navCountry_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
        Auth,
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achiever_notes/search?limit=10&offset=${offSet}&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
  }

  useEffect(() => {
    fetchTableFirst();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function uploadFile(e) {
    if (attachedFile !== undefined || attachedFile !== '') {
      setUpload(true);
      setUploaded(false);
      // var file = copiableData;
      // formData({ ...formData, copiable_data_file_path: file });
      var newDate = new Date();
      var date =
        newDate.getDate() +
        '-' +
        (newDate.getMonth() + 1) +
        '-' +
        newDate.getFullYear() +
        '-' +
        newDate.getHours() +
        '-' +
        newDate.getMinutes() +
        '-' +
        newDate.getSeconds();
      FilePath =
        'stucle-notes/achiever/achiever-notes/' +
        date +
        '-' +
        achieverCodeSelected +
        '/' +
        date +
        '-' +
        userId +
        '-' +
        attachedFile.name;
      // sourceFilePath = UPLOAD_URL + FilePath;
      // console.log(sourceFilePath);
    }
    const params = {
      Body: attachedFile,
      Bucket: `${configuration.bucketName}`,
      Key: FilePath,
    };
    s3.putObject(params)
      .on('build', (request) => {
        request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
        request.httpRequest.headers['Content-Length'] = attachedFile.size;
        request.httpRequest.headers['Content-Type'] = attachedFile.type;
        request.httpRequest.headers['x-amz-acl'] = 'public-read';
        // console.log(request.response.httpResponse);
        sourceFilePath = UPLOAD_URL + FilePath;
        console.log(sourceFilePath);
        setUpload(false);
        setUploaded(true);
      })
      .send((err) => {
        if (err) {
          console.log(err, 'Img error');
          toast.error(err.response.data.message);
          setUpload(false);
        }
      });
  }

  function uploadThumbnailFile(e) {
    if (attThumbnailFile !== undefined || attThumbnailFile !== '') {
      setUploadThumbnail(true);
      setUploadedThumbnail(false);
      // var file = copiableData;
      // formData({ ...formData, copiable_data_file_path: file });
      var newDate = new Date();
      var date =
        newDate.getDate() +
        '-' +
        (newDate.getMonth() + 1) +
        '-' +
        newDate.getFullYear() +
        '-' +
        newDate.getHours() +
        '-' +
        newDate.getMinutes() +
        '-' +
        newDate.getSeconds();
      FilePathThumbnail =
        'stucle-notes/achiever/achiever-notes/test/' +
        date +
        '-' +
        achieverCodeSelected +
        '/' +
        date +
        '-' +
        userId +
        '-' +
        attThumbnailFile.name;
      // sourceFilePathThumbnail = UPLOAD_URL + FilePathThumbnail;
      // console.log(sourceFilePathThumbnail);
    }
    const params = {
      Body: attThumbnailFile,
      Bucket: `${configuration.bucketName}`,
      Key: FilePathThumbnail,
    };
    s3.putObject(params)
      .on('build', (request) => {
        request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
        request.httpRequest.headers['Content-Length'] = attThumbnailFile.size;
        request.httpRequest.headers['Content-Type'] = attThumbnailFile.type;
        request.httpRequest.headers['x-amz-acl'] = 'public-read';
        // console.log(request.response.httpResponse);
        sourceFilePathThumbnail = UPLOAD_URL + FilePathThumbnail;
        console.log(sourceFilePathThumbnail);
        setUploadThumbnail(false);
        setUploadedThumbnail(true);
      })
      .send((err) => {
        if (err) {
          console.log(err, 'Img error');
          toast.error(err.response.data.message);
          setUploadThumbnail(false);
        }
      });
  }

  const fetchOtherData = async (name) => {
    setStartUpload(true);
    try {
      if (name === 'country') {
        const getBoard = await Axios.get(`${BASE_URL}/master_data/boards?country_id=${country_id}`, Auth);
        console.log(getBoard.data.data);
        setDropDownData({ ...dropDownData, allBoards: getBoard.data.data });
        setStartUpload(false);
      } else if (name === 'board') {
        // const getMedium = await Axios.get(
        //   `${BASE_URL}/master_data/mediums?country_id=${country_id}`,
        //   Auth
        // );
        // console.log(getMedium.data.data);
        // setDropDownData((prevState) => ({
        //   ...prevState,
        //   allMediums: getMedium.data.data,
        // }));
        // setStartUpload(false);
        // } else if (name === "language") {
        const getCourse = await Axios.get(`${BASE_URL}/master_data/courses?board_id=${board_id}`, Auth);
        console.log(getCourse.data.data);
        setDropDownData((prevState) => ({
          ...prevState,
          allCourses: getCourse.data.data,
        }));
        setStartUpload(false);
      } else if (name === 'course') {
        const getSubject = await Axios.get(`${BASE_URL}/master_data/subjects?course_id=${course_id}`, Auth);
        console.log(getSubject.data.data);
        setDropDownData((prevState) => ({
          ...prevState,
          allSubjects: getSubject.data.data,
        }));
        setStartUpload(false);
      } else if (name === 'subject') {
        const getTopic = await Axios.get(`${BASE_URL}/master_data/topics?subject_id=${subject_id}`, Auth);
        console.log(getTopic.data.data);
        setDropDownData((prevState) => ({
          ...prevState,
          allTopics: getTopic.data.data,
        }));
        setStartUpload(false);
      } else {
        setStartUpload(false);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
  };

  function handleChange(e) {
    console.log(123, e.target.value, e.target.name);
    var selectedOption = e.target.name;
    if (selectedOption === 'country') {
      country_id = e.target.value;
    } else if (selectedOption === 'board') {
      board_id = e.target.value;
      // } else if (selectedOption === "language") {
      // medium_id = e.target.value;
    } else if (selectedOption === 'course') {
      course_id = e.target.value;
    } else if (selectedOption === 'subject') {
      subject_id = e.target.value;
    } else if (selectedOption === 'topic') {
      topic_id = e.target.value;
      dropDownData.allTopics.forEach((topic) => {
        // console.log(+topic.topic_id, +topic_id);
        if (+topic.topic_id === +topic_id) {
          console.log(747);
          setNotesTitleSelected(topic.topic_display_name);
        }
      });

      // } else if (selectedOption === "achieverName") {
      //   achieverName = e.target.value;
    } else if (selectedOption === 'numberOfPages') {
      numberOfPages = +e.target.value;
      // } else if (selectedOption === "title") {
      // title = e.target.value;
    } else if (selectedOption === 'name') {
      var searchText = e.target.value;
      setAchieverSelected(searchText);
      setAchieverSearch(searchText);
      // } else if (selectedOption === "achieverCode") {
      //   achieverCode = e.target.value;
    } else if (selectedOption === 'attachFile') {
      let file = e.target.files[0];
      if (file) {
        console.log(file.name, e.target.files[0]);
        setAttachedFile(file);
      } else {
        sourceFilePath = '';
        setAttachedFile('');
        setUpload(false);
        setUploaded(false);
      }
    } else if (selectedOption === 'thumbnailFile') {
      let thumbnailFile = e.target.files[0];
      if (thumbnailFile) {
        console.log(thumbnailFile.name, e.target.files[0]);
        setAttThumbnailFile(thumbnailFile);
        sourceFilePathThumbnail = '';
      } else {
        sourceFilePathThumbnail = '';
        setPic('');
        setAttThumbnailFile('');
        setUploadThumbnail(false);
        setUploadedThumbnail(false);
      }
    }
    setNotFilled(false);
    fetchOtherData(selectedOption);
  }

  async function handleEdit(rows) {
    setEditValue(true);
    setStartUpload(true);
    setUploadPreview(false);
    setUploadedPreview(false);
    URLFilePathPreview = [];
    newPreviewURL = [];
    setPreview([]);
    previewArray = [];
    isEditPreviewArray = [false, false, false, false, false];
    console.log(rows.achiever_notes_id);
    try {
      const oneAchiever = await Axios.get(`${BASE_URL}/achiever_notes/${rows.achiever_notes_id}`, Auth);
      console.log("oneAchiever", oneAchiever);
      oneAchieverData = oneAchiever.data.data;
      country_id = oneAchieverData.country_id;
      board_id = oneAchieverData.board_id;
      medium_id = oneAchieverData.medium_id;
      course_id = oneAchieverData.course_id;
      subject_id = oneAchieverData.subject_id;
      topic_id = oneAchieverData.topic_id;
      numberOfPages = oneAchieverData.no_of_pages;
      // achieverName = oneAchieverData.achiever_name;
      // title = oneAchieverData.title;
      // achieverCode = oneAchieverData.achiever_code;
      sourceFilePath = oneAchieverData.notes_file_url;
      sourceFilePathThumbnail = oneAchieverData.notes_thumbnail_file_url;
      setAchieverSelected(oneAchieverData.achiever_name);
      setAchieverCodeSelected(oneAchieverData.achiever_code);
      setNotesTitleSelected(oneAchieverData.title);
      newPreviewURL.push(oneAchieverData.preview_image_1);
      newPreviewURL.push(oneAchieverData.preview_image_2);
      newPreviewURL.push(oneAchieverData.preview_image_3);
      newPreviewURL.push(oneAchieverData.preview_image_4);
      newPreviewURL.push(oneAchieverData.preview_image_5);
      URLFilePathPreview.push(oneAchieverData.preview_image_1);
      URLFilePathPreview.push(oneAchieverData.preview_image_2);
      URLFilePathPreview.push(oneAchieverData.preview_image_3);
      URLFilePathPreview.push(oneAchieverData.preview_image_4);
      URLFilePathPreview.push(oneAchieverData.preview_image_5);
      const showFileName = oneAchieverData?.notes_file_url?.split("/")
      setFileName(showFileName[showFileName.length - 1])
      setRowValues((prevState) => ({
        ...prevState,
        country: oneAchieverData.country_id,
        // name: oneAchieverData.achiever_name,
        achieversId: oneAchieverData.achiever_id,
        numberOfPages: oneAchieverData.no_of_pages,
        title: oneAchieverData.title,
        achieverName: oneAchieverData.achiever_name,
        board: oneAchieverData.board_id,
        course: oneAchieverData.course_id,
        subject: oneAchieverData.subject_id,
        language: oneAchieverData.medium_id,
        topic: oneAchieverData.topic_id,
        hexaCode: oneAchieverData.notes_hexa,
        // achieverCode: oneAchieverData.achiever_code,
        achieverNotesId: oneAchieverData.achiever_notes_id,
        notesFileURL: oneAchieverData.notes_file_url,
        notesThumbnailFileURL: oneAchieverData.notes_thumbnail_file_url,
      }));
      
      setShow(true);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }

    // const fetchOtherData = async (name) => {
    try {
      // if (name === "country") {
      const getBoard = await Axios.get(`${BASE_URL}/master_data/boards?country_id=${country_id}`, Auth);
      console.log(getBoard.data.data);
      setDropDownData({ ...dropDownData, allBoards: getBoard.data.data });
      // } else if (name === "board") {
      const getMedium = await Axios.get(`${BASE_URL}//master_data/mediums?country_id=${country_id}`, Auth);
      console.log(getMedium.data.data);
      setDropDownData((prevState) => ({
        ...prevState,
        allMediums: getMedium.data.data,
      }));
      // } else if (name === "language") {
      const getCourse = await Axios.get(`${BASE_URL}/master_data/courses?board_id=${board_id}`, Auth);
      console.log(getCourse.data.data);
      setDropDownData((prevState) => ({
        ...prevState,
        allCourses: getCourse.data.data,
      }));
      // } else if (name === "course") {
      const getSubject = await Axios.get(`${BASE_URL}/master_data/subjects?course_id=${course_id}&medium_id=${medium_id}`, Auth);
      console.log(getSubject.data.data);
      setDropDownData((prevState) => ({
        ...prevState,
        allSubjects: getSubject.data.data,
      }));
      // } else if (name === "subject") {
      const getTopic = await Axios.get(`${BASE_URL}/master_data/topics?subject_id=${subject_id}`, Auth);
      console.log(getTopic.data.data);
      setDropDownData((prevState) => ({
        ...prevState,
        allTopics: getTopic.data.data,
      }));
      setStartUpload(false);
      // }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
    // };
  }

  const handleStatus = () => {
    return setUserDetails(!userDetails);
  };

  async function handleEnabled() {
    setStatus('Enabled');
    setStartUpload(true);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_notes/search/count?is_enabled=true&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
        Auth,
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achiever_notes/search?limit=10&offset=0&is_enabled=true&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleDisabled() {
    setStatus('Disabled');
    setStartUpload(true);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_notes/search/count?is_enabled=false&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
        Auth,
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achiever_notes/search?limit=10&offset=0&is_enabled=false&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleAll() {
    setStatus('All');
    setStartUpload(true);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    try {
      const rowCount = await Axios.get(
        `${BASE_URL}/achiever_notes/search/count?country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
        Auth,
      );
      console.log(rowCount.data.data.count);
      setNumberOfRows(rowCount.data.data.count);
      if (+rowCount.data.data.count !== 0) {
        const getTable = await Axios.get(
          `${BASE_URL}/achiever_notes/search?limit=10&offset=0&country_id=${navCountry_id}&medium_id=${navMedium_id}&board_id=${navBoard_id}&course_id=${navCourse_id}&subject_id=${navSubject_id}&topic_id=${navTopic_id}&search_text=${navSearch}`,
          Auth,
        );
        console.log(getTable.data.data);
        setTableData(getTable.data.data);
        setStartUpload(false);
      }
      if (+rowCount.data.data.count === 0) {
        setNoData(true);
        setStartUpload(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    handleStatus();
  }

  async function handleCheckbox(e, row) {
    setStartUpload(true);
    var check = e.target.checked;
    console.log(check);
    tempTableData = tableData.map((object) => {
      return object.achiever_notes_id === row.achiever_notes_id ? { ...object, is_enabled: check } : { ...object };
    });
    setTableData(tempTableData);
    const dateEnbleEdit = {
      notes_hexa: row.notes_hexa,
      country_id: row.country_id,
      medium_id: row.medium_id,
      board_id: row.board_id,
      course_id: row.course_id,
      subject_id: row.subject_id,
      topic_id: row.topic_id,
      no_of_pages: row.no_of_pages,
      title: row.title,
      achiever_name: row.achiever_name,
      notes_file_url: row.notes_file_url,
      is_achiever_notes: true,
      achiever_code: row.achiever_code,
      notes_thumbnail_file_url: row.notes_thumbnail_file_url,
      preview_image_1: row.preview_image_1,
      preview_image_2: row.preview_image_2,
      preview_image_3: row.preview_image_3,
      preview_image_4: row.preview_image_4,
      preview_image_5: row.preview_image_5,
      is_enabled: check,
    };
    console.log(dateEnbleEdit);
    try {
      const newAchiever = await Axios.put(`${BASE_URL}/achiever_notes/${row.achiever_notes_id}`, dateEnbleEdit, Auth);
      console.log(newAchiever);
      toast.success('Successfully Edited Achiever Notes');
      setStartUpload(false);
      fetchTable();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
      fetchTable();
    }
  }

  async function handleDelete(rows) {
    console.log('deleteClicked', rows);
    setPopUp(true);
    setDeleteRow(true);
    console.log(123, deleteRow, popUp);
    if (deleteRow === true) {
      console.log(456);
      setStartUpload(true);
      try {
        const deleteRow = await Axios.delete(`${BASE_URL}/achiever_notes/${rows.achiever_notes_id}`, Auth);
        console.log(deleteRow.data.data);
        toast.success('Successfully Deleted Row');
        setStartUpload(false);
        fetchTable();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setStartUpload(false);
      }
      setPopUp(false);
      setDeleteRow(false);
    }
    if (deleteRow === false) {
      rowData = rows;
      console.log(rowData, popUp);
    }
  }

  function okDelete() {
    // setDeleteNode(true);
    handleDelete(rowData);
    // setPopUp(false);
  }

  //function of cancel in popup
  function cancel() {
    setDeleteRow(false);
    setPopUp(false);
  }

  const fetchOtherDataNav = async (name) => {
    setStartUpload(true);
    try {
      if (name === 'country') {
        const getBoard = await (await Axios.get(`${BASE_URL}/master_data/boards?country_id=${navCountry_id}`, Auth)).data;
        console.log(getBoard);
        setNavbarData((prevState) => ({
          ...prevState,
          allBoards: getBoard.data,
        }));
        setStartUpload(false);
      } else if (name === 'board') {
        // const getMediums = await (
        // await Axios.get(
        // `${BASE_URL}/master_data/mediums?country_id=${navCountry_id}`,
        // Auth
        // )
        // ).data;
        // console.log(getMediums);
        // setNavbarData((prevState) => ({
        // ...prevState,
        // allMediums: getMediums.data,
        // }));
        // setStartUpload(false);
        // } else if (name === "language") {
        const getCourse = await (await Axios.get(`${BASE_URL}/master_data/courses?board_id=${navBoard_id}`, Auth)).data;
        console.log(getCourse);
        setNavbarData((prevState) => ({
          ...prevState,
          allCourses: getCourse.data,
        }));
        setStartUpload(false);
      } else if (name === 'course') {
        const getSubject = await (
          await Axios.get(`${BASE_URL}/master_data/subjects?course_id=${navCourse_id}&medium_id=${navMedium_id}`, Auth)
        ).data;
        console.log(getSubject);
        setNavbarData((prevState) => ({
          ...prevState,
          allSubjects: getSubject.data,
        }));
        setStartUpload(false);
      } else if (name === 'subject') {
        const getTopic = await (await Axios.get(`${BASE_URL}/master_data/topics?subject_id=${navSubject_id}`, Auth)).data;
        console.log(getTopic);
        setNavbarData((prevState) => ({
          ...prevState,
          allTopics: getTopic.data,
        }));
      }
      setStartUpload(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
  };

  async function handleNavbar(e, selectedOption) {
    if (selectedOption === 'country') {
      navCountry_id = e.target.value;
      navBoard_id = -1;
      navCourse_id = -1;
      navSubject_id = -1;
      navTopic_id = -1;
      navMedium_id = -1;
    } else if (selectedOption === 'board') {
      navBoard_id = e.target.value;
      navCourse_id = -1;
      navSubject_id = -1;
      navTopic_id = -1;
      navMedium_id = -1;
      // } else if (selectedOption === "language") {
      //   navMedium_id = e.target.value;
    } else if (selectedOption === 'course') {
      navCourse_id = e.target.value;
      navSubject_id = -1;
      navTopic_id = -1;
      navMedium_id = -1;
    } else if (selectedOption === 'subject') {
      navSubject_id = e.target.value;
      navTopic_id = -1;
      navMedium_id = -1;
    } else if (selectedOption === 'topic') {
      navTopic_id = e.target.value;
      navMedium_id = -1;
    }
    await fetchOtherDataNav(selectedOption);
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    setstate((prevState) => ({
      ...prevState,
      country: navCountry_id,
      board: navBoard_id,
      course: navCourse_id,
      subject: navSubject_id,
      topic: navTopic_id,
      medium: navMedium_id,
    }));
  }

  function handleNavSearch(e) {
    var tempNavSearch = e.target.value;
    setPageNumber(1);
    setOffSet(0);
    setNoData(false);
    setNavSearch(tempNavSearch);
    // fetchTable();
  }

  useEffect(() => {
    fetchTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, navSearch]);

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfRows / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpload(true);
    }
  }

  useEffect(() => {
    async function searchItemList() {
      // if (achieverSearch.length > 0) {
      setStartUpload(true);
      try {
        const searchItems = await Axios.get(`${BASE_URL}/achiever_notes/search/achievers?search_text=${achieverSearch}`, Auth);
        console.log(searchItems.data.data);
        setDropDownData({
          ...dropDownData,
          achieverNameList: searchItems.data.data,
        });
        setSearchSuggestion(true);
        setStartUpload(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setStartUpload(false);
      }
      // } else {
      //   setSearchSuggestion(false);
      // }
    }
    searchItemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achieverSearch]);

  function handleAchieverClick(object) {
    setAchieverSelected(object.achiever_name);
    setSearchSuggestion(false);
    setAchieverCodeSelected(object.achiever_code);
  }

  function handleUlClose() {
    setSearchSuggestion(false);
    setAchieverSelected('');
    setAchieverSearch('');
    setAchieverCodeSelected('');
  }

  async function handleOnFocus() {
    console.log('s');
    setStartUpload(true);
    try {
      const searchItems = await Axios.get(`${BASE_URL}/achiever_notes/search/achievers?search_text=${achieverSearch}`, Auth);
      console.log(searchItems.data.data);
      setDropDownData({
        ...dropDownData,
        achieverNameList: searchItems.data.data,
      });
      setSearchSuggestion(true);
      setStartUpload(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setStartUpload(false);
    }
    // } else {
    //   setSearchSuggestion(false);
    // }
  }

  function handleFilePreview(event, imageIndex) {
    console.log(7272);
    // setPreview([]);
    if (event.target.files[1]) {
      for (var i = 0; i < 5 - imageIndex; i++) {
        if (event.target.files[i]) {
          // setFieldValue(
          //   "file",
          //   event.currentTarget.files[i]
          // );
          console.log(i, imageIndex);
          previewArray[i + imageIndex] = event.target.files[i];
          // }
          // if (event.target.files[i]) {
          newPreviewURL[i + imageIndex] = URL.createObjectURL(event.target.files[i]);
          isEditPreviewArray[imageIndex + i] = true;
          console.log(isEditPreviewArray);
        } else {
          newPreviewURL[i] = null;
        }
        // }
      }
    } else {
      previewArray[imageIndex] = event.target.files[0];
      newPreviewURL[imageIndex] = URL.createObjectURL(event.target.files[0]);
      isEditPreviewArray[imageIndex] = true;
      console.log(isEditPreviewArray);
    }
    setPreview([...previewArray]);
  }

  function handleFilePreviewEdit(event, imageIndex) {
    console.log(7272);
    if (event.target.files[0]) {
      previewArray[imageIndex] = event.target.files[0];
      newPreviewURL[imageIndex] = URL.createObjectURL(event.target.files[0]);
      isEditPreviewArray[imageIndex] = true;
    }
    setPreview([...previewArray]);
  }

  function handlePreviewRemove(imageIndex) {
    console.log(566, imageIndex);
    // var tempPreview = preview;
    // tempPreview.splice(imageIndex, 1, 1);
    // console.log(tempPreview);
    // for(var n=0; n<5; n++){
    //   URL.revokeObjectURL(preview[n]);
    // }
    previewArray[imageIndex] = null;
    setPreview([...previewArray]);
    URLFilePathPreview[imageIndex] = null;
    previewArray[imageIndex] = null;
    newPreviewURL[imageIndex] = null;
    isEditPreviewArray[imageIndex] = false;
    console.log(URLFilePathPreview);
    // setUploadPreview(false);
    // setUploadedPreview(false);
    // setAttThumbnailFile("");
    // setUploadThumbnail(false);
    // setUploadedThumbnail(false);
    // setRowValues({
    //   ...rowValues,
    //   previewURL1: null,
    // });
  }
  function previewUpload(j) {
    // if (attThumbnailFile !== undefined || attThumbnailFile !== "") {
    setUploadPreview(true);
    setUploadedPreview(false);
    // var file = copiableData;
    // formData({ ...formData, copiable_data_file_path: file });
    var newDate = new Date();
    var date =
      newDate.getDate() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getFullYear() +
      '-' +
      newDate.getHours() +
      '-' +
      newDate.getMinutes() +
      '-' +
      newDate.getSeconds();
    FilePathPreview =
      'stucle-notes/achiever/achiever-notes/' +
      date +
      '-' +
      achieverCodeSelected +
      '/' +
      date +
      '-' +
      userId +
      '-' +
      preview[j].name;
    // URLFilePathPreview.push(UPLOAD_URL + FilePathPreview);
    // console.log(URLFilePathPreview);
    // }
    const params = {
      Body: preview[j],
      Bucket: `${configuration.bucketName}`,
      Key: FilePathPreview,
    };
    s3.putObject(params)
      .on('build', (request) => {
        request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
        request.httpRequest.headers['Content-Length'] = preview[j].size;
        request.httpRequest.headers['Content-Type'] = preview[j].type;
        request.httpRequest.headers['x-amz-acl'] = 'public-read';
        // console.log(request.response.httpResponse);
        URLFilePathPreview[j] = UPLOAD_URL + FilePathPreview;
        isEditPreviewArray[j] = false;
        console.log(URLFilePathPreview);
        setUploadPreview(false);
        setUploadedPreview(true);
      })
      .send((err) => {
        if (err) {
          console.log(err, 'Img error');
          toast.error(err.response.data.message);
          setUploadPreview(false);
        }
      });
  }

  function uploadAll() {
    if (editValue) {
      if (sourceFilePathThumbnail === '') {
        uploadThumbnailFile();
      }

      for (var m = 0; m < preview.length; m++) {
        console.log(isEditPreviewArray);
        if (isEditPreviewArray[m]) {
          console.log(preview.length, m);
          // if (!URLFilePathPreview[m]) {
          previewUpload(m);
          // }
        }
      }
    } else {
      if (preview.length > 0 && attachedFile && attThumbnailFile) {
        if (sourceFilePath === '') {
          uploadFile();
        }
        if (sourceFilePathThumbnail === '') {
          uploadThumbnailFile();
        }
        URLFilePathPreview = [];
        console.log(isEditPreviewArray);
        for (var j = 0; j < preview.length; j++) {
          // if (preview[j] !== null && preview[j] !== undefined) {
          if (isEditPreviewArray[j]) {
            previewUpload(j);
          }
        }
      } else {
        toast.error('Select all the file to be uploaded');
      }
    }
  }

  return (
    <div>
      <NavbarNotes handleNavbar={handleNavbar} handleNavSearch={handleNavSearch} navbarData={navbarData} statValue={state} />
      <div className="container-fluid">
        {startUpload ? <ReactLoading type="spin" color="#2f80ed" className="startUploadLoadSpin" /> : null}
        <div className="head2">
          <div>
            <h1 className="h1AchieversNotes">Achievers notes</h1>
          </div>
          <div className="headButton">
            <button className="statusButton" onClick={handleStatus}>
              {status}
            </button>
            <button className="addAchieverNotesButton" onClick={handleShow}>
              Add Achiever note
            </button>
          </div>
          <div onClick={handleStatus} className={userDetails ? 'overlay ' : 'noOverlay '}></div>
          <div className={userDetails ? 'allStatusDropdown' : 'noDropdown'}>
            <button className={status === 'Enabled' ? 'logoutButtonBlue' : 'logoutButton'} onClick={handleEnabled}>
              Enabled
            </button>
            <button className={status === 'Disabled' ? 'logoutButtonBlue' : 'logoutButton'} onClick={handleDisabled}>
              Disabled
            </button>
            <button className={status === 'All' ? 'logoutButtonBlue' : 'logoutButton'} onClick={handleAll}>
              All
            </button>
          </div>
        </div>
        {noData ? (
          <h5 className="loadingTextData">Data is not available.</h5>
        ) : (
          <div>
            <div className="tableContainer">
              <table className="tableAchNotes">
                <thead>
                  <tr>
                    {thead_column.map((el, key) => {
                      return <th key={key}>{el}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((rows, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <div>
                            {/* <button
                        className="editButton"
                        onClick={() => handleEdit(rows)}
                      >
                        <img src={EditIcon} alt="" />
                      </button> */}
                            <button className="talkDeleteIconBtn" onClick={() => handleEdit(rows)}>
                              <EditIcon className="talkDeleteIcon" />
                            </button>
                            <button className="talkDeleteIconBtn" onClick={(e) => handleDelete(rows)}>
                              <DeleteIcon className="talkDeleteIcon" />
                            </button>
                          </div>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={rows.is_enabled}
                            onChange={(e) => handleCheckbox(e, rows)}
                          />
                        </td>
                        <td>{rows.country}</td>
                        <td className="achieversID">{rows.achiever_code}</td>
                        <td className="rowNames">{rows.achiever_name}</td>
                        <td>{rows.notes_hexa}</td>
                        <td className="date">{rows.updated_date}</td>
                        <td className="titleCaption">{rows.title}</td>
                        <td>{rows.board}</td>
                        <td>{rows.course}</td>
                        <td>{rows.subject}</td>
                        {/* <td>{rows.medium}</td> */}
                        <td>{rows.topic}</td>
                        <td>{rows.no_of_pages}</td>
                        <td>{rows.rating}</td>
                        <td className="views">{rows.views}</td>
                        <td className="viewTime">{rows.view_time}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* <div className="paginationNotes">
            <Stack spacing={2} align="center">
              <Pagination
                count={5}
                color="primary"
                align="center"
                showFirstButton
                showLastButton
              />
            </Stack>
          </div> */}
            <Pagination paginationClick={paginationClick} numberOfRows={numberOfRows} offSet={offSet} pageNumber={pageNumber} />
          </div>
        )}

        <ModalComponent
          show={show}
          onClick={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
          onChange={handleChange}
          title={editValue ? 'Edit Achievers Notes' : 'Add Achievers Notes'}
          className="addAchieverPopup"
        >
          <div className="addAchieverForm">
            <Formik
              initialValues={editValue ? rowValues : initialValues}
              // validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleChange, setFieldValue }) => {
                return (
                  <div>
                    <Form autoComplete="off">
                      <label className={notFilled && country_id === -1 ? 'lableAchieverRed' : 'lableAchiever'}>Country</label>
                      <br />
                      <Field
                        as="select"
                        name="country"
                        onChange={handleChange}
                        className="selectOption"
                        // defaultValue={editValue ? rowValues.country : undefined}
                      >
                        <option value="-1">Country</option>
                        {dropDownData.country.map((e, key) => {
                          return (
                            <option value={e.country_id} key={key}>
                              {e.country_name}
                            </option>
                          );
                        })}
                      </Field>
                      <br />
                      <label className={notFilled && board_id === -1 ? 'lableAchieverRed' : 'lableAchiever'}>Board</label>
                      <br />
                      <Field
                        as="select"
                        name="board"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.board : undefined}
                      >
                        <option value="-1">Board</option>
                        {dropDownData.allBoards.map((e, key) => {
                          return (
                            <option value={e.board_id} key={key}>
                              {e.display_name}
                            </option>
                          );
                        })}
                      </Field>
                      {/* <br /> */}
                      {/* <label
                        className={
                          notFilled && medium_id === -1
                            ? "lableAchieverRed"
                            : "lableAchiever"
                        }
                      >
                        Language
                      </label> */}
                      {/* <br /> */}
                      {/* <Field
                        as="select"
                        name="language"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.language : undefined}
                      >
                        <option value="-1">Language</option>
                        {dropDownData.allMediums.map((e, key) => {
                          return (
                            <option value={e.medium_id} key={key}>
                              {e.medium_name}
                            </option>
                          );
                        })}
                      </Field> */}
                      <br />
                      <label className={notFilled && course_id === -1 ? 'lableAchieverRed' : 'lableAchiever'}>Course</label>
                      <br />
                      <Field
                        as="select"
                        name="course"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.courses : undefined}
                      >
                        <option value="-1">Course</option>
                        {dropDownData.allCourses.map((e, key) => {
                          return (
                            <option value={e.course_id} key={key}>
                              {e.display_name}
                            </option>
                          );
                        })}
                      </Field>
                      <br />
                      <label className={notFilled && subject_id === -1 ? 'lableAchieverRed' : 'lableAchiever'}>Subject</label>
                      <br />
                      <Field
                        as="select"
                        name="subject"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.subject : undefined}
                      >
                        <option value="-1">Subject</option>
                        {dropDownData.allSubjects.map((e, key) => {
                          return (
                            <option value={e.subject_id} key={key}>
                              {e.subject_display_name}
                            </option>
                          );
                        })}
                      </Field>
                      <br />

                      <label className={notFilled && topic_id === -1 ? 'lableAchieverRed' : 'lableAchiever'}>Topic</label>
                      <br />
                      <Field
                        as="select"
                        name="topic"
                        onChange={handleChange}
                        className="selectOption"
                        // value={editValue ? rowValues.topic : undefined}
                      >
                        <option value="-1">Topic</option>
                        {dropDownData.allTopics.map((e, key) => {
                          return (
                            <option value={e.topic_id} key={key}>
                              {e.topic_display_name}
                            </option>
                          );
                        })}
                      </Field>
                      <label className={notFilled && numberOfPages === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                        Number of Pages
                      </label>
                      <br />
                      <Field
                        type="number"
                        name="numberOfPages"
                        className="input"
                        // value={editValue ? rowValues.title : ""}
                      />

                      <br />
                      <label className={notFilled && achieverSelected === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                        Achiever Name
                      </label>
                      <br />
                      <SearchSuggestion
                        achieverSelected={achieverSelected}
                        disabled={editValue}
                        handleChange={handleChange}
                        searchSuggestion={searchSuggestion}
                        dropDownData={dropDownData.achieverNameList}
                        handleUlClose={handleUlClose}
                        handleUlClick={handleAchieverClick}
                        handleOnFocus={handleOnFocus}
                      />
                      {/* <br /> */}
                      <label className={notFilled && notesTitleSelected === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                        Notes Title
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="title"
                        className="input"
                        value={notesTitleSelected}
                        disabled={true}
                        // value={editValue ? rowValues.title : ""}
                      />

                      <br />
                      {/* <ErrorMessage name="name" component="div">
                             {(error) => <div className={styles.errors}>{error}</div>}
                            </ErrorMessage> */}
                      <label className={notFilled && achieverCodeSelected === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                        Achiever Code
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="achieverCode"
                        className="input"
                        value={achieverCodeSelected}
                        disabled={true}
                        // value={editValue ? rowValues.achieversId : ""}
                      />
                      <br />

                      {/* <label className="lableAchiever">Hexa Code</label>
                    <br />
                    <Field
                      type="text"
                      name="hexaCode"
                      className="input"
                      // value={editValue ? rowValues.name : ""}
                    />

                    <br /> */}

                      <label className={notFilled && sourceFilePath === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                        Attach file
                      </label>
                      <br />
                      {fileName.length > 0 ? <><label className={notFilled && sourceFilePath === '' ? 'lableAchieverRed' : 'lableAchiever'}>{fileName}</label>
                      <br/></> : null}
                      <label htmlFor="attachFIle" className="inputAttach">
                        Attach notes file
                      </label>
                      <Field
                        type="file"
                        name="attachFile"
                        className="insideText"
                        accept=".stn"
                        disabled={editValue || !achieverCodeSelected}
                        // onChange={uploadFile}
                      />
                      {attachedFile !== '' ? (
                        <div>
                          <p className="fileName">{attachedFile.name}</p>
                          {/* <div className="notesUploadDiv">
                            <button
                              type="button"
                              onClick={uploadFile}
                              className="btnUpload"
                              disabled={uploaded}
                            >
                              Upload
                            </button>
                            <div>
                              {upload ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading
                                    type="spinningBubbles"
                                    color="#2f80ed"
                                    className="uploadLoadSpin"
                                  />
                                  <p>Uploading...</p>
                                </div>
                              ) : null}
                              {uploaded ? <p>File Uploaded</p> : null}
                            </div>
                          </div> */}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="thumbnailDiv">
                        <label className={notFilled && sourceFilePathThumbnail === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                          Thumbnail
                        </label>
                        <br />
                        <label htmlFor="attachFIle" className={attachedFile !== '' ? 'thumbnail' : 'thumbnailDisabled'}>
                          Attach thumbnail
                        </label>
                        <div
                          className={
                            // attachedFile === ""
                            //   ? "thumbnailHiddenTextBefore"
                            //   :
                            attThumbnailFile !== '' ? 'thumbnailHiddenTextLater' : 'thumbnailHiddenText'
                          }
                        >
                          <img
                            src={editValue ? rowValues.notesThumbnailFileURL : pic ? URL.createObjectURL(pic) : null}
                            alt={pic ? pic.name : null}
                            className={editValue ? 'previewImg' : pic ? 'previewImg' : ''}
                          />
                          <input
                            id="thumbnailFile"
                            name="thumbnailFile"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(event) => {
                              setFieldValue('file', event.currentTarget.files[0]);
                              setPic(event.currentTarget.files[0]);
                              if (event.currentTarget.files[0]) {
                                var newURL = URL.createObjectURL(event.currentTarget.files[0]);
                                setRowValues({
                                  ...rowValues,
                                  notesThumbnailFileURL: newURL,
                                });
                              }
                            }}
                            className="notesThumbnailbutton"
                            disabled={editValue ? false : attachedFile !== '' ? false : true}
                          />
                          {pic ? (
                            <button
                              name="removeFile"
                              type="button"
                              onClick={() => {
                                setPic('');
                                setAttThumbnailFile('');
                                setUploadThumbnail(false);
                                setUploadedThumbnail(false);
                                setRowValues({
                                  ...rowValues,
                                  notesThumbnailFileURL: null,
                                });
                              }}
                              className="thumbnailremoveButton"
                              disabled={editValue}
                            >
                              Remove{' '}
                            </button>
                          ) : null}
                        </div>
                        {attThumbnailFile !== '' ? (
                          <div>
                            <p className="fileName">{attThumbnailFile.name}</p>
                            {/* <div className="notesUploadDiv">
                              <button
                                type="button"
                                onClick={uploadThumbnailFile}
                                className="btnUpload"
                                disabled={uploadedThumbnail}
                              >
                                Upload
                              </button>
                              <div>
                                {uploadThumbnail ? (
                                  <div className="noteLoadingDiv">
                                    <ReactLoading
                                      type="spinningBubbles"
                                      color="#2f80ed"
                                      className="uploadLoadSpin"
                                    />
                                    <p>Uploading...</p>
                                  </div>
                                ) : null}
                                {uploadedThumbnail ? (
                                  <p>File Uploaded</p>
                                ) : null}
                              </div>
                            </div> */}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <br />
                      <label className={notFilled && sourceFilePathThumbnail === '' ? 'lableAchieverRed' : 'lableAchiever'}>
                        Preview-Image
                      </label>
                      <br />
                      {!editValue ? (
                        <div className="previewDiv">
                          {Array.apply(null, { length: 5 }).map((e, imageIndex) => {
                            return (
                              <div key={imageIndex}>
                                <div className="labelInputPreviewDiv">
                                  <label
                                    htmlFor="attachFIle"
                                    className={attachedFile !== '' ? 'thumbnailPreview' : 'thumbnailDisabledPreview'}
                                  >
                                    Attach Preview Image {imageIndex + 1}
                                  </label>
                                  {/* <div
                                    className={
                                      // attachedFile === ""
                                      //   ? "thumbnailHiddenTextBefore"
                                      //   :
                                      attThumbnailFile !== ""
                                        ? "thumbnailHiddenTextLaterPreview"
                                        : "thumbnailHiddenTextPreview"
                                    }
                                  > */}
                                  {/* {preview.map(function (
                                      imgDetails,
                                      imgIndex
                                    ) {
                                      return ( */}
                                  <img
                                    // key={imgIndex}
                                    src={
                                      editValue
                                        ? newPreviewURL[imageIndex]
                                        : preview[imageIndex] && preview[imageIndex] !== 1
                                          ? URL.createObjectURL(preview[imageIndex])
                                          : null
                                    }
                                    alt={preview[imageIndex] && preview[imageIndex] !== 1 ? preview[imageIndex].name : null}
                                    className={editValue ? 'previewImgPreview' : preview ? 'previewImgPreview' : ''}
                                  />
                                  {/* //   ); */}
                                  {/*  })} */}
                                  <input
                                    multiple
                                    id="previewFile"
                                    name="previewFile"
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(event) => handleFilePreview(event, imageIndex)}
                                    className="notesThumbnailbuttonPreview"
                                    disabled={editValue ? false : attachedFile !== '' && attThumbnailFile !== '' ? false : true}
                                    // value=''
                                  />
                                  {preview[imageIndex] ? (
                                    <button
                                      name="removeFile"
                                      type="button"
                                      onClick={(e) => handlePreviewRemove(imageIndex)}
                                      className="thumbnailremoveButtonPreview"
                                      // disabled={editValue}
                                    >
                                      Remove{' '}
                                    </button>
                                  ) : null}
                                  {preview[imageIndex] ? (
                                    <div>
                                      <p className="fileNamePreview">{preview[imageIndex].name}</p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {/* </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="previewDiv">
                          {Array.apply(null, { length: 5 }).map((e, imageIndex) => {
                            return (
                              <div key={imageIndex}>
                                <div className="labelInputPreviewDiv">
                                  <label
                                    htmlFor="attachFIle"
                                    className={attachedFile !== '' ? 'thumbnailPreview' : 'thumbnailDisabledPreview'}
                                  >
                                    Attach Preview Image {imageIndex + 1}
                                  </label>
                                  {/* <div
                                    className={
                                      // attachedFile === ""
                                      //   ? "thumbnailHiddenTextBefore"
                                      //   :
                                      attThumbnailFile !== ""
                                        ? "thumbnailHiddenTextLaterPreview"
                                        : "thumbnailHiddenTextPreview"
                                    }
                                  > */}
                                  {/* {preview.map(function (
                                      imgDetails,
                                      imgIndex
                                    ) {
                                      return ( */}
                                  <img
                                    // key={imgIndex}
                                    src={
                                      editValue
                                        ? newPreviewURL[imageIndex]
                                        : preview[imageIndex] && preview[imageIndex] !== 1
                                          ? URL.createObjectURL(preview[imageIndex])
                                          : null
                                    }
                                    alt={preview[imageIndex] && preview[imageIndex] !== 1 ? preview[imageIndex].name : null}
                                    className={editValue ? 'previewImgPreview' : preview ? 'previewImgPreview' : ''}
                                  />
                                  {/* //   ); */}
                                  {/*  })} */}
                                  <input
                                    // multiple
                                    id="previewFile"
                                    name="previewFile"
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(event) => handleFilePreviewEdit(event, imageIndex)}
                                    className="notesThumbnailbuttonPreview"
                                    disabled={editValue ? false : attachedFile !== '' && attThumbnailFile !== '' ? false : true}
                                    // value=''
                                  />
                                  {newPreviewURL[imageIndex] ? (
                                    <button
                                      name="removeFile"
                                      type="button"
                                      onClick={(e) => handlePreviewRemove(imageIndex)}
                                      className="thumbnailremoveButtonPreview"
                                      // disabled={editValue}
                                    >
                                      Remove{' '}
                                    </button>
                                  ) : null}
                                  {preview[imageIndex] ? (
                                    <div>
                                      <p className="fileNamePreview">{preview[imageIndex].name}</p>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {/* </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {preview.length > 0 || (editValue && attThumbnailFile !== '') ? (
                        <div>
                          {/* <p className="fileName">{attThumbnailFile.name}</p> */}
                          <div className="notesUploadDiv">
                            <button type="button" onClick={uploadAll} className="btnUploadNotes" disabled={uploadedPreview}>
                              Upload
                            </button>
                            <div>
                              {upload ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading type="spinningBubbles" color="#2f80ed" className="uploadLoadSpin" />
                                  <p className="notesUploadP">Uploading File...</p>
                                </div>
                              ) : null}
                              {uploaded ? <p className="notesUploadP">File Uploaded</p> : null}
                            </div>
                            <div>
                              {uploadThumbnail ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading type="spinningBubbles" color="#2f80ed" className="uploadLoadSpin" />
                                  <p className="notesUploadP">Uploading Thumbnail...</p>
                                </div>
                              ) : null}
                              {uploadedThumbnail ? <p className="notesUploadP">Thumbnail Uploaded</p> : null}
                            </div>
                            <div>
                              {uploadPreview ? (
                                <div className="noteLoadingDiv">
                                  <ReactLoading type="spinningBubbles" color="#2f80ed" className="uploadLoadSpin" />
                                  <p className="notesUploadP">Uploading Preview...</p>
                                </div>
                              ) : null}
                              {uploadedPreview ? <p className="notesUploadP">Preview Uploaded</p> : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <br />

                      <hr />

                      <Box component="div" className="footerAddAchiever">
                        <button type="button" onClick={handleClose} className="btnCancel">
                          <div>Cancel</div>
                        </button>

                        <button
                          type="submit"
                          // onClick={onSubmit}
                          className="btnSave"
                        >
                          <div>Save</div>
                        </button>
                      </Box>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </ModalComponent>
        <div onClick={cancel} className={popUp ? 'popUpBack' : 'noPopUp'}>
          <div className={popUp ? 'yesPopUp' : 'noPopUp'}>
            <button onClick={cancel} className="popUpCloseBtn">
              <CloseOutlinedIcon className="closeIcon" />
            </button>
            <div className="warningDiv">
              <ReportOutlinedIcon className="warningIcon" />
            </div>
            <h2 className="popUph2">Are You Sure?</h2>
            <div className="popUpButtonDiv">
              <button onClick={cancel} className="popUpCancelButton">
                Cancel
              </button>
              <button onClick={okDelete} className="popUpOkButton">
                OK
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default AchieversNotes;
