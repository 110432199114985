import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import AchieversInfo from '../Screens/AchieversInfo/AchieversInfo.jsx';
import AchieversNotes from '../Screens/AchieversNotes/AchieversNotes';
import AchieversTalks from '../Screens/AchieversTalks/AchieversTalks';
import StudentFeedback from '../Screens/StudentFeedback/StudentFeedback';
import Reports from '../Screens/Reports/Reports';
import Settings from '../Screens/Settings/Settings';

// import routes from "../../routes";
// import Navbar from "../Header/Navbar";

const switchRoutes = (
  <Switch>
    <Route exact path="/admin/achievers_info" component={AchieversInfo} />
    <Route exact path="/admin/achievers_notes" component={AchieversNotes} />
    <Route exact path="/admin/achievers_talks" component={AchieversTalks} />
    <Route exact path="/admin/student_feedback" component={StudentFeedback} />
    <Route exact path="/admin/reports" component={Reports} />
    <Route exact path="/admin/settings" component={Settings} />
    <Redirect from="/admin" to="/admin/achievers_info" />
  </Switch>
);

var i = 0;
var timeStampExpire;
var expiryTime;

const Layout = () => {
  const getRoute = () => {
    return window.location.pathname !== null;
  };

  timeStampExpire = window.localStorage.getItem('expiry');
  if (window.location.pathname !== '/' && i === 0) {
    expiryTime = timeStampExpire - Date.now();
    console.log(expiryTime, i);
    i = 1;
    if (expiryTime < 0) {
      toast.error('Session has expired. Login again to continue...');
      setTimeout(() => {
        window.localStorage.clear();
        window.location = '/';
      }, 3000);
    } else {
      setTimeout(() => {
        toast.error('Session has expired. Login again to continue...');
        setTimeout(() => {
          window.localStorage.clear();
          window.location = '/';
        }, 3000);
      }, expiryTime);
    }
  }

  if (window.localStorage.getItem('token') === null) {
    return (window.location.href = '/');
  }
  return (
    <>
      <div>
        {/* <Navbar /> */}
        <div>{getRoute() ? <div>{switchRoutes}</div> : null}</div>
        <p className="developer">Developed by Sagar Bazar</p>
      </div>
    </>
  );
};

export default Layout;
