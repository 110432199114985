import React from 'react';
// import styles from "../../../styles.css";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import LogoApps from '../../../assets/images/LogoApps.png';
import { BASE_URL } from '../../config/Api';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  email: '',
};
const validationSchema = Yup.object({
  email: Yup.string().required('Email is required'),
});

function ForgotPassword() {
  //   const [email, setEmail] = useState("admin@gmail.com");
  //   const [login, setLogin] = useState(false);

  const onSubmit = (value) => {
    let data = { email_id: value.email };
    console.log(data);
    Axios.post(`${BASE_URL}/login/forgot_password`, data).then(
      (response) => {
        console.log(response);
        toast.success('Mail Sent Successfully!');
        if (response.data.status === true) {
          // setTimeout(() => {
          //     return window.location="/"
          //     setLogin(false);
          // }, 3000)
        }
      },
      (error) => {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
      },
    );
  };

  return (
    <div>
      <div className="logo">
        <Link className="logoBtn" to="/">
          <img src={LogoApps} alt="Logo-Apppsndevices" />
        </Link>
      </div>
      <div>
        <hr className="line"></hr>
      </div>
      <div className="loginContent">
        <div className="loginTitle">
          <h1 className="h1Reset">Forgot Password</h1>
        </div>

        <div className="formDiv">
          <Box component="div">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
              {(formik) => {
                return (
                  <div>
                    <Form>
                      <Box component="div" className="username">
                        <Field type="email" id="email" name="email" placeholder="Email Id" className="username_field" />
                      </Box>
                      <ErrorMessage name="email" component="div">
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <button type="submit" className="btn">
                        <div className="btnText">Submit</div>
                      </button>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <p className="developer">Developed by Sagar Bazar</p>
    </div>
  );
}

export default ForgotPassword;
