import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { Redirect, Link } from 'react-router-dom';
import './login.css';
import LogoApps from '../../../assets/images/LogoApps.png';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../config/Api';

const initialValues = {
  email: '',
  password: '',
};
const validationSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
});

function Login() {
  const [login, setLogin] = useState(false);

  function onSubmit(value) {
    // console.log(value.email);
    // console.log(value.password);
    const data = {
      email_id: value.email,
      password: value.password,
    };
    Axios.post(`${BASE_URL}/login/admin-signin`, data)
      .then((response) => {
        toast.success('Login Successful');
        console.log(response.data.data);
        window.localStorage.setItem('token', response.data.data.token);
        window.localStorage.setItem('expiry', response.data.data.expiry);
        // window.localStorage.setItem("expiry", Date.now() + 10000);
        window.localStorage.setItem('userId', response.data.data.id);
        window.localStorage.setItem('userName', response.data.data.full_name);
        setTimeout(() => {
          setLogin(true);
        }, 3000);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      });
  }

  if (login) {
    return <Redirect from="/" to="/admin" />;
  }

  return (
    <div>
      <div className="logo">
        <Link className="logoBtn" to="/">
          <img src={LogoApps} alt="Logo-Apppsndevices" />
        </Link>
      </div>
      <div>
        <hr className="line"></hr>
      </div>
      <div className="loginContent">
        <div className="loginTitle">
          <h1 className="h1login">Admin Portal</h1>
        </div>

        <div className="formDiv">
          <Box component="div">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
              {(formik) => {
                return (
                  <div>
                    <Form>
                      <Box component="div" className="username">
                        <Field type="email" id="email" name="email" placeholder="Email Id" className="username_field" />
                      </Box>
                      <ErrorMessage name="email" component="div">
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <Box component="div" className="password">
                        <Field type="password" id="password" name="password" placeholder="Password" className="password_field" />
                      </Box>
                      <ErrorMessage name="password" component="div">
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <div>
                        <Link to="/forgot_password" className="forgotPassword">
                          Forgot Password?
                        </Link>
                      </div>
                      <button type="submit" className="btn">
                        <div className="btnText">Sign in</div>
                      </button>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <p className="developer">Developed by Sagar Bazar</p>
    </div>
  );
}

export default Login;
