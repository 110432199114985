import React from 'react';
import Login from './Screens/Login/Login';
import ForgotPassword from './Screens/ForgotPassword/ForgotPassword';
import DeleteAccount from './Screens/DeleteAccount/DeleteAccount';
import ResetPassword from './Screens/ResetPassword/ResetPassword';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../styles.css';
import Layout from './Layout/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/forgot_password" component={ForgotPassword} />
          <Route path="/:user_id/new_password" component={ResetPassword} />
          <Route path="/admin" component={Layout} />
          <Route path="/delete_account" component={DeleteAccount} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
