import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import LogoWhite from '../../../../assets/images/Logo-white.png';
import './navbarNotes.css';

function Navbar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState(false);

  const userName = window.localStorage.getItem('userName');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    return window.localStorage.clear();
  };

  const handleUserDetails = () => {
    return setUserDetails(!userDetails);
  };

  return (
    <div>
      <div className="navbar">
        <div className="navbar1">
          <div>
            <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenu} className="menuIcon">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <Link to="/admin/achievers_info" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementInfo">
                  Achievers Info
                </MenuItem>
              </Link>
              <Link to="/admin/achievers_notes" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementNotes">
                  Achiever Notes
                </MenuItem>
              </Link>
              <Link to="/admin/achievers_talks" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementTalks">
                  Achiever Talks
                </MenuItem>
              </Link>
              <Link to="/admin/group_course" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementGroup">
                  Group Course
                </MenuItem>
              </Link>
              <Link to="/admin/reports" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementReports">
                  Reports
                </MenuItem>
              </Link>
              <Link to="/admin/student_feedback" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementSettings">
                  Student Feedback
                </MenuItem>
              </Link>
              <Link to="/admin/settings" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementSettings">
                  Settings
                </MenuItem>
              </Link>
            </Menu>
          </div>

          <img src={LogoWhite} alt="" />
          <div className="searchInput">
            <input className="navsearch" type="search" placeholder="Search" onChange={(event) => props.handleNavSearch(event)} />
          </div>

          <select
            className="navbut"
            name="India"
            id="India"
            onChange={(e) => props.handleNavbar(e, 'country')}
            value={props.statValue.country}
          >
            <option value="-1">Country</option>
            {props.navbarData.country.map((e, key) => {
              return (
                <option value={e.country_id} key={key}>
                  {e.country_name}
                </option>
              );
            })}
          </select>
          <select
            className="navbut"
            name="Board"
            id="Board"
            onChange={(e) => props.handleNavbar(e, 'board')}
            value={props.statValue.board}
          >
            <option value="-1">Board</option>
            {props.navbarData.allBoards.map((e, key) => {
              return (
                <option key={key} value={e.board_id}>
                  {e.display_name}
                </option>
              );
            })}
          </select>
          <select
            className="navbut"
            name="course"
            id="course"
            onChange={(e) => props.handleNavbar(e, 'course')}
            value={props.statValue.course}
          >
            <option valur="-1">Course</option>
            {props.navbarData.allCourses.map((e, key) => {
              return (
                <option key={key} value={e.course_id}>
                  {e.display_name}
                </option>
              );
            })}
          </select>
          <select
            className="navbut"
            name="Subject"
            id="Subject"
            onChange={(e) => props.handleNavbar(e, 'subject')}
            value={props.statValue.subject}
          >
            <option value="-1">Subject</option>
            {props.navbarData.allSubjects.map((e, key) => {
              return (
                <option key={key} value={e.subject_id}>
                  {e.subject_display_name}
                </option>
              );
            })}
          </select>
          <select
            className="navbut"
            name="Topics"
            id="Topics"
            onChange={(e) => props.handleNavbar(e, 'topic')}
            value={props.statValue.topic}
          >
            <option value="-1">Topic</option>
            {props.navbarData.allTopics.map((e, key) => {
              return (
                <option key={key} value={e.topic_id}>
                  {e.topic_display_name}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <button onClick={handleUserDetails} className="navbut1">
            {userName}
          </button>
        </div>
      </div>
      <div onClick={handleUserDetails} className={userDetails ? 'overlay ' : 'noOverlay '}></div>
      <div className={userDetails ? 'dropdown' : 'noDropdown'}>
        <Link to="/">
          <button onClick={handleLogout} className="logoutButton">
            Logout
          </button>
        </Link>
        <button className="logoutButton">Account</button>
      </div>
    </div>
  );
}

export default Navbar;
