import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import LogoWhite from '../../../../assets/images/Logo-white.png';
import './navbarInfo.css';
import Axios from 'axios';
import { BASE_URL } from '../../../config/Api';
import ReactLoading from 'react-loading';
import { toast, ToastContainer } from 'react-toastify';
// import SessionExpiry from "../../../SubComponents/SessionExpiry/SessionExpiry";

// var country_id = -1;

function Navbar(props) {
  const { name, categories, handleFromDateChange, handleToDateChange, fromDate, toDate, getFeedback } = props;

  // const [state, setstate] = useState({
  //   country: -1,
  // });
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [navbarData, setNavbarData] = useState({
    country: [],
  });

  const token = window.localStorage.getItem('token');
  // const userId = window.localStorage.getItem("userId")
  const userName = window.localStorage.getItem('userName');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  const fetchOptions = async () => {
    setStartUpload(true);
    try {
      const getCountry = await Axios.get(`${BASE_URL}/master_data/countries/`, Auth);
      // const getBoards = await (
      //   await Axios.get(`${BASE_URL}/choice/boards`, Auth)
      // ).data;
      setNavbarData((prevState) => ({
        ...prevState,
        country: getCountry.data.data,
        // allBoards: getBoards.data,
      }));
      setStartUpload(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setStartUpload(false);
    }
  };

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    return window.localStorage.clear();
  };

  const handleUserDetails = () => {
    return setUserDetails(!userDetails);
  };

  return (
    <div>
      {startUpload ? <ReactLoading type="spin" color="#2f80ed" className="startUploadLoadSpin" /> : null}
      <div className="navbar">
        <div className="navbar1">
          <div>
            <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenu} className="menuIcon">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <Link to="/admin/achievers_info" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementInfo">
                  Achievers Info
                </MenuItem>
              </Link>
              <Link to="/admin/achievers_notes" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementNotes">
                  Achiever Notes
                </MenuItem>
              </Link>
              <Link to="/admin/achievers_talks" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementTalks">
                  Achiever Talks
                </MenuItem>
              </Link>
              <Link to="/admin/group_course" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementGroup">
                  Group Course
                </MenuItem>
              </Link>
              <Link to="/admin/reports" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementReports">
                  Reports
                </MenuItem>
              </Link>
              <Link to="/admin/student_feedback" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementSettings">
                  Student Feedback
                </MenuItem>
              </Link>
              <Link to="/admin/settings" className="linkOtherPage">
                <MenuItem onClick={handleClose} className="iconPlacementSettings">
                  Settings
                </MenuItem>
              </Link>
            </Menu>
          </div>

          <img src={LogoWhite} alt="" />
          {name !== 'feedback' && (
            <div className="searchInput">
              <input className="navsearch" type="search" placeholder="Search" onChange={props.handleNavSearch} />
            </div>
          )}

          {name !== 'feedback' && (
            <select className="navbut" name="India" id="India" onChange={(e) => props.handleNavbar(e, 'country')}>
              <option value="-1">Country</option>
              {navbarData.country.map((e, key) => {
                return (
                  <option value={e.country_id} key={key}>
                    {e.country_name}
                  </option>
                );
              })}
            </select>
          )}
          {name === 'feedback' && (
            <select
              className="navbut"
              name="feedback_category"
              id="feedback_category"
              onChange={(e) => props.handleNavbar(e, 'feedback_category')}
            >
              <option value="">Category</option>
              {categories.map((ele, index) => {
                return (
                  <option value={ele.feedback_category} key={index}>
                    {ele.feedback_category}
                  </option>
                );
              })}
            </select>
          )}
          {name === 'feedback' && (
            <div className="feedback_date_picker_holder">
              <div>
                <label htmlFor="from" className="feedback_date_picker_holder--label">
                  From :
                </label>
                <input
                  type="date"
                  id="from"
                  name="from"
                  className="feedback_datepicker"
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </div>
              <div>
                <label htmlFor="to" className="feedback_date_picker_holder--label">
                  To :
                </label>
                <input type="date" id="to" name="to" className="feedback_datepicker" value={toDate} onChange={handleToDateChange} />
              </div>
              <button className="get_feedback_btn" onClick={getFeedback}>
                Get feedback
              </button>
            </div>
          )}
        </div>
        <div>
          <button onClick={handleUserDetails} className="navbut1">
            {userName}
          </button>
        </div>
      </div>
      <div onClick={handleUserDetails} className={userDetails ? 'overlay ' : 'noOverlay '}></div>
      <div className={userDetails ? 'dropdown' : 'noDropdown'}>
        <Link to="/">
          <button onClick={handleLogout} className="logoutButton">
            Logout
          </button>
        </Link>
        <button className="logoutButton">Account</button>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Navbar;
